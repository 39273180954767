<template>
  <div id="app">
    <div v-if="currentType == 'loading'">
      <Confirmation/>
    </div>

    <div v-if="currentType == 'car'" :key="index">
      <div
          v-if="url_color"
          class="corner fixed py-4 px-20 flex justify-center items-center"
          :style="urlColor"
      >
        <div class="line-height text-6xl font-semibold flex items-center">
          {{ company_url }}
        </div>
      </div>


      <div class="flex flex-col w-screen h-screen relative">
        <div class="flex flex-1 h-full">
          <!-- LEFT SIDE -->
          <div class="flex w-1/2">

            <!-- details -->
            <div class="relative h-full flex items-center w-full flex-1" :style="[backgroundColor, textColor]">
              <div class="w-full h-full flex justify-center flex-col p-28">
                <div class="flex items-center w-full mb-6 pb-6 border-b-2 border-gray-200 font-bold text-64 6xl:text-7xl 7xl:text-8xl 11xl:text-9xl 13xl:text-10xl 14xl:text-11xl 15xl:text-12xl 6xl:mb-12">
                  <div class="w-full truncate text-shadow animate__animated animate__fadeInUp mr-2">
                    <div class="truncate uppercase" style="line-height: 100px">{{ vehicle.brandLabel }}</div>
                    <div class="truncate uppercase" style="line-height: 100px;margin-top: -24px">{{ vehicle.modelLabel }}</div>
                  </div>
                </div>

                <div class="flex flex-col">
                  <div class="animate__animated animate__fadeInUp mb-6">
                    <div>
                      <p class="text-5xl 6xl:text-7xl 7xl:text-8xl 11xl:text-9xl 13xl:text-10xl 14xl:text-11xl 15xl:text-12xl font-bold leading-none">
                        € {{ vehicle.price }}
                      </p>
                      <p class="font-bold text-sm 6xl:text-4xl 7xl:text-5xl 11xl:text-6xl 13xl:text-7xl 14xl:text-8xl 15xl:text-9xl leading-10">
                        <span :style="{color: textColorByLanguage('nl')}">BTW incl.</span> / <span :style="{color: textColorByLanguage('fr')}">TVA incl.</span>
                      </p>
                    </div>
                  </div>

                  <div class="flex">
                    <div class="w-1/2 text-xl 6xl:text-2xl 7xl:text-3xl 11xl:text-4xl 13xl:text-5xl 14xl:text-6xl 15xl:text-7xl animate__animated animate__fadeInUp">
                      <ul class="space-y-5">
                        <li class="flex items-start">
                          <div class="flex items-start mr-4" style="width: 45px">
                            <font-awesome-icon :icon="['fal', 'badge-check']" class="text-xl mt-2"/>
                          </div>
                          <div v-if="parseInt(vehicle.warranty) === 1">
                            <div :style="{color: textColorByLanguage('nl')}">1 jaar garantie /</div>
                            <div :style="{color: textColorByLanguage('fr')}">1 an garantie</div>
                          </div>
                          <div v-if="parseInt(vehicle.warranty) > 1">
                            <div :style="{color: textColorByLanguage('nl')}">{{ vehicle.warranty }} jaar garantie /</div>
                            <div :style="{color: textColorByLanguage('fr')}">{{ vehicle.warranty }} ans de garantie</div>
                          </div>
                        </li>
                        <li class="flex items-start">
                          <div class="flex items-start  mr-4" style="width: 45px">
                            <font-awesome-icon :icon="['fal', 'road']" class="text-xl mt-2"/>
                          </div>
                          {{ formatNumber(vehicle.kilometers) }} km
                        </li>
                        <li class="flex items-start">
                          <div class="flex items-start mr-4" style="width: 45px">
                            <font-awesome-icon :icon="['far', 'calendar-week']" class="text-xl mt-2"/>
                          </div>
                          {{ vehicle.firstRegistrationMonth }}/{{ vehicle.firstRegistrationYear }}
                        </li>
                        <li class="flex items-start">
                          <div class="flex items-start mr-4" style="width: 45px">
                            <font-awesome-icon :icon="['far', 'charging-station']" class="text-xl mt-2"/>
                          </div>
                          <div class="flex flex-col items-start">
                            <div :style="{color: textColorByLanguage('nl')}">{{ vehicle.fuelType['nl'] }} /</div>
                            <div :style="{color: textColorByLanguage('fr')}">{{ vehicle.fuelType['fr'] }}</div>
                          </div>
                        </li>
                        <li class="flex items-start">
                          <div class="flex items-start  mr-4" style="width: 45px">
                            <font-awesome-icon :icon="['far', 'leaf']" class="text-xl mt-2"/>
                          </div>
                          {{ vehicle.co2 }} g/km, {{ vehicle.euroNorm }}
                        </li>
                        <li class="flex items-start">
                          <div class="flex items-start mr-4" style="width: 45px">
                            <font-awesome-icon :icon="['far', 'tachometer-alt-fast']" class="text-xl mt-2"/>
                          </div>
                          {{ vehicle.kw }} kw/{{ vehicle.cv }} pk
                        </li>

                      </ul>
                    </div>

                    <div class="w-1/2 text-xl 6xl:text-2xl 7xl:text-3xl 11xl:text-4xl 13xl:text-5xl 14xl:text-6xl 15xl:text-7xl">
                      <div class="flex flex-col animate__animated animate__fadeInUp">
                        <div v-for="eq in vehicle.equipments" class="inline-flex items-start w-full mb-5">
                          <font-awesome-icon :icon="['far', 'plus']" class="mr-4 text-xl mt-2" :style="{color: branding_color}"/>
                          <div class="w-full truncate">
                            <div class="truncate w-full" :style="{color: textColorByLanguage('nl')}">{{ eq['nl'] }}  /</div>
                            <div class="truncate w-full" :style="{color: textColorByLanguage('fr')}">{{ eq['fr'] }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex items-center justify-start animate__animated animate__fadeInUp bg-gray-100 mt-14 p-6 rounded-lg">
                  <qr-code :text="generatedUrl" :size="140"></qr-code>

                  <div class="text-2xl ml-6">
                    <div :style="{color: '#000000'}">Ontdek dit voertuig /</div>
                    <div :style="{color: '#181818'}">Découvrez ce véhicule</div>
                  </div>


                </div>
              </div>

            </div>


          </div>

          <!-- RIGHT SIDE -->
          <div class="flex flex-col w-1/2">
            <!-- main img -->
            <div class="flex relative" style="aspect-ratio: 4/3">
              <div
                  v-if="url_color && vehicle && vehicle.displayReference"
                  class="corner-left absolute bottom-0 right-0 py-4 px-20 flex justify-center items-center"
                  :style="urlColor"
              >
                <div class="line-height text-6xl font-semibold flex items-center">
                  {{ vehicle.displayReference }}
                </div>
              </div>

              <div class="w-full">
                <img
                    :src="imageBySet(vehicle, 1)"
                    alt="vehicle"
                    class="mainImageSlide absolute inset-0 h-full w-full object-cover z-50"
                />
              </div>
            </div>

            <div class="flex h-full">
              <!-- 2nd img -->
              <div class="flex items-center justify-center w-1/2 overflow-hidden relative">
                <img
                    :src="imageBySet(vehicle, 2)"
                    alt="vehicle"
                    height="100%"
                    class="secondImageSlide h-full w-full absolute inset-0 object-cover object-center"
                />
              </div>

              <!-- 3rd img -->
              <div class="flex items-center justify-center w-1/2 overflow-hidden relative">
                <img
                    :src="imageBySet(vehicle, 3)"
                    alt="vehicle"
                    height="100%"
                    class="thirdImageSlide h-full w-full absolute inset-0 object-cover object-center"
                />
              </div>
            </div>


          </div>

        </div>

        <div class="absolute bottom-0 h-2 w-full">
          <div class="h-full" :style="[backgroundBrandingColor, { width: '0%', animation: `growWidth ${animationDuration}s forwards` }]"/>
        </div>
      </div>


      <!-- full screen -->
      <div v-if="currentType == 'youtube'"
           class="w-screen h-screen flex"
           :key="index"
      >
        <div
            class="w-screen h-screen flex items-center justify-center text-7xl text-white"
        >
          <div id="player_yt"></div>
        </div>
      </div>
      <div v-if="currentType == 'loading'">
        <div
            class="w-screen h-screen flex items-center justify-center text-7xl"
        >
          <font-awesome-icon :icon="['fas', 'spinner']" pulse/>
        </div>
      </div>
    </div>
  </div>
  <!-- app -->
</template>

<script>
import Confirmation from "./components/Confirmation";
import tinycolor from "tinycolor2";
import axios from "axios";
import {filter, find, has, isObject, isString, map, orderBy, take} from "lodash";

export default {
  name: "App",
  components: {
    Confirmation,
  },
  data() {
    return {
      showStarted: false,
      currentLanguage: "nl",
      vehicles: [],
      images: [],
      company_logo: "",
      logo_background_color: "",
      branding_color: "",
      company_url: "",
      url_color: "",
      theme: "",
      background_color: "",
      text_color: "",
      vehicle: null,
      index: 0,
      currentIndex: 0,
      currentItem: null,
      currentType: "loading",
      currentTime: localStorage.getItem("currentTime"),
      deviceCode: null,
      imagesBySet: []
    };
  },

  watch: {
    // whenever deviceCode changes, this function will run
    deviceCode(newCode, oldCode) {
      if (newCode || this.vehicles.length < 1) {
        this.fetchVehicles();
      }
    },
    theme(newTheme, oldTheme) {
      let theme = null
      if(newTheme) theme = newTheme;
      if(theme) document.body.classList.add(theme);
    }
  },

  methods: {
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    imageBySet(vehicle, position) {
      const imageSetKey = find(this.playlist, item => item.reference === vehicle.reference)?.imageSetKey || 'naked'; // get imageSetKey or use naked as fallback
      const imageSet = find(vehicle.imageSets, set => set["@key"] === imageSetKey)?.images?.image || []; // get images by imageSet or use empty array as fallback
      return find(imageSet, image => parseInt(image.position) === parseInt(position))?.uri || this.company_logo // get image uri or use company logo as fallback
    },

    textColorByLanguage(language) {
      if(!language) return this.text_color;

      if(language === 'nl') return this.text_color;
      if(language === 'fr') return tinycolor(this.text_color).lighten(25).toString()

      return this.text_color;
    },

    fetchVehicles(onSuccess) {
      axios
          .get("/vehicles", {
            params: {
              device_code: localStorage.getItem("randomNumber"),
            },
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            const data = response.data.autralis;
            this.vehicles = [];
            for (let dealerIndex = 0; dealerIndex < data.dealership.length; dealerIndex++) {
              // console.log("Vehicles: ", filter(data.dealership, dealership => dealership?.vehicle?.length > 0))
              for (let vehicleIndex = 0; vehicleIndex < data.dealership[dealerIndex].vehicle.length; vehicleIndex++) {
                this.vehicles.push(this.mappedVehicle(data.dealership[dealerIndex].vehicle[vehicleIndex])
                );
              }
            }

            if (onSuccess && typeof onSuccess === "function") {
              onSuccess();
            }

            const intervalID = setInterval(() => {
              axios
                  .get("/lists", {
                    headers: {
                      "Content-Type": "application/json",
                    },
                    params: {
                      device_code: localStorage.getItem("randomNumber"),
                    },
                  })
                  .then((response) => {
                    this.playlist = this.mappedPlaylist(response.data.result);
                    this.company_logo = response.data.company_logo;
                    this.theme = response?.data?.theme_key
                    this.logo_background_color =
                        response.data.logo_background_color;
                    this.branding_color = response.data.branding_color;
                    this.company_url = response.data.company_url;
                    this.url_color = response.data.url_color;
                    this.background_color = response.data.background_color;
                    this.text_color = response.data.text_color;
                    this.startShow();
                    clearInterval(intervalID);
                  });
            }, 5000);
          })

          .catch(function (error) {
            console.log(error);
          });
    },

    startShow() {
      if (this.showStarted) return;
      this.showStarted = true;
      this.index = -1;
      const setNextSlide = () => {
        this.index++;
        if (this.index >= this.playlist.length) {
          this.index = 0;
        }
        this.currentItem = this.playlist[this.index];
        this.currentType = this.currentItem.type;
        this.currentLength = this.currentItem.length;

        if (this.currentType == "car") {
          const item = this.vehicles.find(
              (v) => v.reference == this.currentItem.reference
          );

          if (item) {
            this.vehicle = item;
          } else {
            setNextSlide();
            console.log("no vehicle...");
          }
        } else {
        }
      };

      const showNextOne = () => {
        setTimeout(() => {
          setNextSlide();
          showNextOne();
        }, this.currentLength * 1000);
      };

      setNextSlide();
      showNextOne();
    },

    mappedPlaylist(result) {
      const references = this.vehicles.map((v) => v.reference);
      return result.filter((r) => {
        if (r.type != "car") return true;
        return references.includes(r.reference);
      });
    },

    mappedImages(vehicle) {
      const imageSetKey = "naked";
      if (!vehicle?.media?.imagesets) return [];
      const imageSet = vehicle?.media?.imagesets.find(
          (imgSet) => imgSet["@key"] == imageSetKey
      );
      let images = vehicle?.media?.images?.image;
      if (imageSet) {
        images = imageSet.images?.image;
      }
      const imagesPositions = [1, 2, 3, 4];
      return map(
          filter(images, (image) =>
              imagesPositions.includes(parseInt(image?.position))
          ),
          (image) => image?.uri
      );
    },

    mappedEquipments(equipments) {
      const totalEquipments = 4;

      return take(map(orderBy(equipments, ['priority']), (eq) => {
        return {
          "nl": eq?.['nl'] || '',
          "fr": eq?.['fr'] || '',
        }
      }), totalEquipments);
    },

    mappedVehicle(vehicle) {
      return {
        reference: vehicle["@reference"],
        displayReference: vehicle?.displayReference || "",
        brandLabel: vehicle?.brandLabel || "",
        modelLabel: vehicle?.modelLabel || "",
        warranty: vehicle?.warranty || "",
        price: vehicle?.price?.B2C ? this.formatNumber(vehicle.price.B2C) : "",
        images: this.mappedImages(vehicle),
        imageSets: vehicle?.media?.imagesets || [],
        kilometers: vehicle.kilometers ? this.formatNumber(vehicle.kilometers) : "",
        fuelType: {
          'nl': vehicle.fueltype?.['nl'] || "",
          'fr': vehicle.fueltype?.['fr'] || "",
        },
        kw:
            isObject(vehicle.power) && has(vehicle.power, "KW")
                ? vehicle.power.KW
                : "",
        cv:
            isObject(vehicle.power) && has(vehicle.power, "CV")
                ? vehicle.power.CV
                : "",
        co2: vehicle?.co2 || "",
        euroNorm: isString(vehicle.euro)
            ? vehicle.euro
            : isObject(vehicle.euro) && has(vehicle.euro, "#text")
                ? vehicle.euro["#text"]
                : "",
        firstRegistrationMonth: vehicle?.firstRegistration?.month
            ? vehicle.firstRegistration.month
            : "",
        firstRegistrationYear: vehicle?.firstRegistration?.year
            ? vehicle.firstRegistration.year
            : "",
        equipments: vehicle?.equipements?.eq
            ? this.mappedEquipments(vehicle?.equipements?.eq)
            : [],
      };
    },
  },

  computed: {
    animationDuration() {
      return this.currentLength;
    },

    urlColor() {
      return {
        color: this.url_color,
      };
    },
    backgroundColor() {
      return {
        "background-color": this.background_color,
      };
    },

    backgroundBrandingColor() {
      return {
        "background-color": this.branding_color,
      };
    },
    textColor() {
      return {
        color: this.text_color,
      };
    },
    generatedUrl() {
      return `${this.company_url}/nl/vehicle?reference=${this.vehicle.reference}`;
    }
  },

  updated() {
    if (this.currentType === "youtube") {
      var player;
      player = new YT.Player("player_yt", {
        height: window.screen.height,
        width: window.screen.width,
        videoId: this.currentItem.reference,
        events: {
          onReady: onPlayerReady,
        },
      });

      function onPlayerReady(event) {
        event.target.playVideo();
      }
    }
  },

  mounted() {
    this.deviceCode = localStorage.getItem("randomNumber");
    this.fetchVehicles();
    const vehiclesInterval = setInterval(() => {
      if (this.deviceCode && this.vehicles.length < 1) {
        this.fetchVehicles(() => {
          clearInterval(vehiclesInterval);
        });
      }
    }, 5000);

    const currentTime = new Date();
    const timeAfter4Hours = currentTime.getHours() + 4;
    localStorage.setItem("currentTime", currentTime);

    setInterval(() => {
      let timeVal = new Date().getHours();
      if (timeAfter4Hours == timeVal) {
        location.reload();
      }
    }, 1000);


  },
};
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
